<template>
  <div class="page-detail shared-index">
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="displayType" @tab-click="handleChangeTab" type="card">
          <el-tab-pane
            v-for="item in position.options"
            :key="item"
            :name="item"
          >
            <div slot="label">
              {{ item | getPlaceType }}
              <span v-if="item === displayType">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="handleOpenFormPopup(null)"
        >Thêm nội dung</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên nội dung"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-contents
        :data="tableData"
        :loading="loading"
        :type="displayType"
        @edit="handleOpenFormPopup"
        @delete="handleDeleteContent"
      ></list-contents>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <content-form-popup
      v-if="visible"
      :visible="visible"
      :content="content"
      :position="position"
      :page="pageInfo"
      :type="displayType"
      @reload="loadData"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteDisplayModel, getDisplayModels } from '@/services/display-model'
import ListContents from './contents/List'
import ContentFormPopup from './popups/ContentForm.vue'

export default {
  name: 'PageContents',
  components: {
    ListContents,
    ContentFormPopup
  },
  props: {
    pageInfo: Object,
    position: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      callingAPI: false,
      searchText: '',
      tableData: [],
      limit: 10,
      page: 1,
      total: 0,
      content: null,
      displayType: this.position.options[0]
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        locale: this.language,
        display_position: this.position.id,
        page_id: this.pageInfo.label_short,
        page: this.page,
        per_page: this.limit,
        display_type: this.displayType,
        order: '-ordered',
        banner_id: this.pageInfo.id
      }
    }
  },
  watch: {
    position: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.position.id) {
        this.loading = true
        getDisplayModels(this.params).then((response) => {
          this.tableData = response.result
          this.total = response.result.length
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    },

    handleOpenFormPopup(content) {
      this.content = content
      this.visible = true
    },

    handleChangeTab() {
      this.page = 1
      this.loadData()
    },

    handleDeleteContent(content) {
      this.$confirm('Bạn có chắc chắn muốn gỡ nội dung này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteDisplayModel(content.id).then(async () => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Gỡ nội dung thành công'
          })
          if (this.tableData.length === 1 && this.page > 1) {
            this.page = this.page - 1
          }
          this.loading = false
          this.loadData()
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể gỡ nội dung'
          })
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
